import { css } from 'react-emotion'

export const FloatingRuleStyle = css`
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -25px;
    height: 3px;
    width: 105px;
    border-radius: 1.5px;
  }
`

export default FloatingRuleStyle
