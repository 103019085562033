import React from 'react'
import styled, { css } from 'react-emotion'
import {
  SplitLayoutContainerStyle,
  ConstrainedContentStyle,
} from './LayoutContainer'
import theme, { media } from '../styles/theme'

export const TeamMember = ({ imageSrc, name, title, bio }) => (
  <div
    className={css`
      display: flex;
      flex-direction: column;
      text-align: left;

      ${media.greaterThan('xSmall')`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, auto);
        grid-gap: 0 30px;
      `};

      ${media.greaterThan('small')`
        display: flex;
      `};
    `}>
    <TeamMemberImageContainer>
      <TeamMemberImage src={imageSrc} alt={title} />
    </TeamMemberImageContainer>
    <TeamMemberName>{name}</TeamMemberName>
    <TeamMemberTitle>{title}</TeamMemberTitle>
    <div dangerouslySetInnerHTML={{ __html: bio }} />
  </div>
)

export const TeamMembers = ({ children }) => (
  <div
    className={css`
      ${SplitLayoutContainerStyle};
      ${ConstrainedContentStyle};
      margin-bottom: 100px;
      margin-top: 30px;

      ${media.greaterThan('small')`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 50px;
      `};
    `}>
    {children}
  </div>
)

export const TeamMemberImageContainer = styled.div`
  padding-bottom: 133.696%;
  height: 0;
  position: relative;
  margin-bottom: 15px;

  ${media.greaterThan('xSmall')`
    margin-bottom: 25px;
    grid-area: 1 / 1 / 4 / 2;
  `};

  ${media.greaterThan('small')`
    margin-bottom: 25px;
  `};
`

export const TeamMemberImage = styled.img`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const TeamMemberName = styled.p`
  color: ${theme.colors.teamMemberName};
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  margin: 0;
`

export const TeamMemberTitle = styled.p`
  color: ${theme.colors.TeamMemberTitle};
  font-size: 16px;
  line-height: 32px;
  font-weight: 300;
  margin: 0;
`

export default TeamMember
