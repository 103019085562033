import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { css } from 'react-emotion'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import HeroContent from '../components/HeroContent'
import {
  HeroHeadingPrimary,
  HeroHeadingSecondary,
} from '../components/HeroHeadings'
import LayoutContainer, {
  ConstrainedContent,
  SplitLayoutContainer,
} from '../components/LayoutContainer'
import Lead from '../components/Lead'
import SectionHeading from '../components/SectionHeading'
import TeamMember, { TeamMembers } from '../components/TeamMember'

export default ({ data, pageContext }) => {
  const page = data.page.data
  const { site } = data

  return (
    <Layout>
      <Helmet>
        <title>About Us - {site.siteMetadata.title}</title>
      </Helmet>
      <Hero
        {...pageContext.heroProps}
        src={page.heroImage.url}
        alt={`${page.heroText} ${page.heroSubheader}`}>
        <HeroContent>
          <HeroHeadingPrimary>{page.heroText}</HeroHeadingPrimary>
          <HeroHeadingSecondary>{page.heroSubheader}</HeroHeadingSecondary>
        </HeroContent>
      </Hero>
      <LayoutContainer center={true}>
        {/*
          // bring this back in once client can enter content
          {page.introduction.html && (
            <Lead dangerouslySetInnerHTML={{ __html: page.introduction.html }} />
          )}
          {page.body.html && (
            <ConstrainedContent
              dangerouslySetInnerHTML={{ __html: page.body.html }}
              className={css`
                margin-bottom: 100px;
              `}
            />
          )}
        */}
        <SectionHeading lighter={true} center={true} smaller={true}>
          {page.teamHeader}
        </SectionHeading>
        <div dangerouslySetInnerHTML={{ __html: page.teamDescription.html }} />
        {page.teamMembers.length && (
          <TeamMembers>
            {page.teamMembers.map(member => (
              <TeamMember
                key={member.photo.url}
                imageSrc={member.photo.url}
                name={member.name}
                title={member.title}
                bio={member.bio.html}
              />
            ))}
          </TeamMembers>
        )}
      </LayoutContainer>
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    page: prismicAbout {
      data {
        heroText: hero_text
        heroSubheader: hero_subheader
        heroImage: hero_image {
          url
          dimensions {
            width
            height
          }
        }
        introduction {
          html
        }
        body {
          html
        }
        teamHeader: team_header
        teamDescription: team_description {
          html
        }
        teamMembers: team_members {
          name: member_name
          title: member_title
          bio: member_bio {
            html
          }
          photo: member_photo {
            url
            dimensions {
              width
              height
            }
          }
        }
      }
    }
  }
`
