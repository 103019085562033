import styled from 'react-emotion'
import { rem } from 'polished'
import { media } from '../styles/theme'

export const HeroHeadingPrimary = styled.p`
  color: #fff;
  font-size: ${rem('48px')};
  line-height: 1;
  font-weight: bold;
  text-align: center;
  margin: 0 0 20px;
  padding: 0 30px;
  text-shadow: 0 0 40px rgba(0, 0, 0, 0.25);

  ${media.greaterThan('small')`
    font-size: ${rem('72px')};
    margin: 0;
  `};
`

export const HeroHeadingSecondary = styled.p`
  color: #fff;
  font-size: ${rem('18px')};
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  padding: 0 30px;

  ${media.greaterThan('small')`
    font-size: ${rem('24px')};
  `};
`
