import styled, { css } from 'react-emotion'
import { rem } from 'polished'
import FloatingRuleStyle from '../styles/FloatingRuleStyle'
import theme, { media } from '../styles/theme'

export const SectionHeading = styled.h2`
  color: ${theme.colors.generalHeading};
  font-size: ${rem('32px')};
  line-height: 1.25;
  font-weight: 800;
  position: relative;

  p {
    font-size: inherit;
    line-height: inherit;
  }

  ${media.greaterThan('small')`
    font-size: ${rem('48px')};
  `};

  ${p =>
    p.lighter &&
    css`
      font-weight: 500;
    `}

  ${p =>
    p.smaller &&
    css`
      font-size: 36px;
    `}

  ${FloatingRuleStyle};

  &:after {
    background-color: ${theme.colors.introRule};
  }

  ${p =>
    p.center &&
    css`
      &:after {
        left: 50%;
        transform: translateX(-50%);
      }
    `}
`

export default SectionHeading
